import { CommonModule } from "@angular/common";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { AuthService } from "@sportyano/core/services/authServices/auth.service";
import { SinglePlayerService } from "@sportyano/main-site/players/single-player.service";
import { CartComponent } from "@sportyano/shared/components/cart/cart.component";
import { PaginationComponent } from "@sportyano/shared/components/paggination/paggination.component";
import { TextLimiterDirective } from "@sportyano/shared/directives/text-limiter/text-limiter.directive";

@Component({
	selector: "app-news",
	templateUrl: "./news.component.html",
	styleUrl: "./news.component.scss",
	standalone: true,
	imports: [
		CommonModule,
		PaginationComponent,
		CartComponent,
		TextLimiterDirective,
		TranslateModule
	],
})
export class NewsComponent implements OnInit {
	// ViewChild
	@ViewChild(CartComponent) cartComponent!: CartComponent;
	userType: UserType;
	authenticated: boolean;
	pageIndex: number = 1;
	pageSize: number = 10;
	@Input() Data: any;

	constructor(
		private singlePlayerService: SinglePlayerService,
		private _authService: AuthService
	) {}

	ngOnInit(): void {		
		this.userType = this._authService.getUserType();
		if (this.userType) {
			this.authenticated = true;
		} else {
			this.authenticated = false;
		}
		// this.singlePlayerService.singlePlayer$.subscribe((res) => {
		// 	console.log(res,'News Component');
		// 	this.playerData = res;
		// });
	}

	isPlayground(): boolean {
		return this.userType == permission.playground;
	}

	changePage(e: any) {
		this.pageIndex = e.pageIndex;
		this.pageSize = e.pageSize;
	}
}
