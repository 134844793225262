import { Component, inject, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MediaService } from "@sportyano/core/services/media/media.service";

@Component({
	selector: "app-homepage-carousel",
	templateUrl: "./homepage-carousel.component.html",
	styleUrls: ["./homepage-carousel.component.scss"],
})
export class HomepageCarouselComponent implements OnInit {
	// Inputs
	@Input() pageType: string;
	// Injects
	public translateService = inject(TranslateService);
	private _mediaService = inject(MediaService);
	responsiveOptions:
		| { breakpoint: string; numVisible: number; numScroll: number }[]
		| undefined;
	public slidesStore: { src: string; title: string }[] = [];
	ngOnInit(): void {
		this.responsiveOptions = [
			{
				breakpoint: "1199px",
				numVisible: 1,
				numScroll: 1,
			},
			{
				breakpoint: "991px",
				numVisible: 1,
				numScroll: 1,
			},
			{
				breakpoint: "767px",
				numVisible: 1,
				numScroll: 1,
			},
		];
		this._HeadOfPageImages(this.pageType);
	}
	private _HeadOfPageImages(pageType: string) {
		this._mediaService.getHeadOfPageImages(pageType).subscribe({
			next: (res: any) => {				
				this.slidesStore = res.records.map((image: any) => ({
					src: image.image,
					title_en: image.title_en,
					title_ar:image.title_ar,
				}));
				if(res.records.length <= 0) {
					this.slidesStore = this.storeOfImages
				}
			}
		});
	}
	public storeOfImages: any[] = [
		{
			src: this.translateService.instant('assets.academiesSport'),
			title: "the first regional sport platform",
		},
		{
			src: this.translateService.instant('assets.stadiumBanner'),
			title: "the first regional sport platform"
		},
		{
			src: this.translateService.instant('assets.playgroundStadium'),
			title: "the first regional sport platform",
		},
		{
			src: this.translateService.instant('assets.academiesSport'),
			title: "the first regional sport platform",
		},
	];
}
