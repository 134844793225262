<main>
	<p-carousel [value]="slidesStore" [numVisible]="1" [numScroll]="1" [circular]="true"
		[responsiveOptions]="responsiveOptions" class="relative" [autoplayInterval]="8000">
		<ng-template let-slider pTemplate="item">
			<img [src]="slider.src" [alt]="slider.title" class="relative  w-full min-h-[45vh]  object-cover " />
			<div class="title">
				<h1 class="slider-header  text-fontColor uppercase font-black  text-3xl lg:text-6xl md:text-4xl ">
					{{ translateService.currentLang == 'en' ? slider.title_en : slider.title_ar}}
				</h1>
			</div>
		</ng-template>
	</p-carousel>
</main>
