<div class="relative w-full  rounded-lg bg-darkBlue">
	<div class="grid grid-cols-12 gap-4">
		@for (news of Data; track $index) {
		<!-- <app-news-card [news]="item" /> -->

		<cart
		class="col-span-12 md:col-span-6"
		[title]="''" [image]="news.image_url" [type]="'academyandplayground'">
			<div postContent class="flex flex-col">
				<p
					TextLimiter
					[maxChars]="50"
					[seeMoreText]="'text.seeMore' | translate"
					class="desc break-large-text mt-3 text-fontColor text-sm word-break-word"
				>
					{{ news?.content }}
				</p>
				<ng-container *ngIf="cartComponent">
					<!-- created_at -->
					<ng-container
						*ngTemplateOutlet="
							cartComponent?.itemDataTemplate!;
							context: {
								primeIcon: 'pi-calendar',
								value: news?.created_at | date
							}
						"
					></ng-container>
				</ng-container>
			</div>
		</cart>
		}
	</div>

	<paggination
		[rows]="10"
		[totalRecords]="Data.length"
		(pageChanged)="changePage($event)"
	/>
</div>
